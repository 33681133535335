export function switchHeader(): void {
  $('.js-white-logo').each(function() {
    const target_element = $(this);
    $(window).scroll(function() {
      const offsetTop = target_element.offset().top;
      const offsetBottom = target_element.offset().top + target_element.outerHeight();
      const scroll = $(this).scrollTop();
      if(offsetTop - 25 < scroll && scroll < offsetBottom - 25){
        $('.global-header').addClass('top-page-header');
      }else{
        $('.global-header').removeClass('top-page-header');
      }
    });
  });
}
