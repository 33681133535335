import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

export function flowSlider(): void {
  const breakPoint = 1025;
  let mySwiper ;
  let mySwiperBool ;

  window.addEventListener('load',()=>{
    if( breakPoint < window.innerWidth){
      mySwiperBool = false;
    }else{
      createSwipter();
      mySwiperBool = true;
    }
  },false);

  window.addEventListener('resize',()=>{
    if( breakPoint < window.innerWidth && mySwiperBool){
      mySwiper.forEach(function(swiper) {
        swiper.destroy(false,true);
      });
      mySwiperBool = false;
    }else if( breakPoint >= window.innerWidth && !(mySwiperBool)){
      createSwipter();
      mySwiperBool = true;
    }
  },false);

  const createSwipter = () =>{
    mySwiper = new Swiper('.js-flow-slider', {
      slidesPerView: "auto",
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      spaceBetween: 30,
      centeredSlides: true,
    });
  }
}
