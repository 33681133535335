


































































import Vue from "vue";
import ReAgreeTermItemForm from "@components/elements/form/ReAgreeTermItemForm.vue";

export default Vue.extend({
  components: {
    ReAgreeTermItemForm,
  },
  props: {
    reAgreeTermFormJsonString: {
      type: String,
      required: true,
    },
    authenticationToken: {
      type: String,
      required: true,
    },
    formUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    const reAgreeTermForm = JSON.parse(this.reAgreeTermFormJsonString);
    return {
      reAgreeTermForm,
      options: {
        width: "90%",
        maxWidth: 600,
        height: "auto",
      }
    }
  },
  mounted() {
    this.$modal.show('reAgreeTermModal');
  },
});
