export function accordion(): void {
  $('.flow_examination-detail h4').click(function(){
    $('.flow_examination-detail ul').slideToggle();
    $(this).toggleClass('open');
  });

  $('.faq_list_question').click(function(){
    $(this).parent().find('.faq_list_answer').slideToggle(
      "fast",
    );
    $(this).toggleClass('open');
  });
}
