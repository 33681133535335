import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

export function projectsSlider(): void {
  const breakPoint = 768;
  let mySwiper ;
  let mySwiperBool ;

  window.addEventListener('load',()=>{
    if( breakPoint > window.innerWidth){
      mySwiperBool = false;
    }else{
      createSwipter();
      mySwiperBool = true;
    }
  },false);

  window.addEventListener('resize',()=>{
    if( breakPoint > window.innerWidth && mySwiperBool){
      mySwiper.destroy(false,true);
      mySwiperBool = false;
    }else if( breakPoint <= window.innerWidth && !(mySwiperBool)){
      createSwipter();
      mySwiperBool = true;
    }
  },false);

  const createSwipter = () =>{
    mySwiper = new Swiper('.js-project-slider', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: "auto",
    });
  };
}
