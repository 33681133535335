

































import Vue from "vue";
export default Vue.extend({
  props: {
    closeBtnText: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {
        width: "90%",
        maxWidth: 600,
        height: "auto",
      }
    }
  },
  mounted() {
    this.$modal.show('errorModal');
  },
});
