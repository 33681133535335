
































import Vue from "vue";
import { ValidationProvider } from "vee-validate";

export type Term = {
  id: number;
  kind: string;
  name: string;
  file_url: string;
  current_version_id: number;
}

export type ReAgreeTermItemForm = {
  term: Term;
  term_id: number;
  viewed: boolean;
}

export default Vue.extend({
  components: {
    ValidationProvider,
  },
  props: {
    reAgreeTermItemForm: {
      type: Object as Vue.PropType<ReAgreeTermItemForm>,
      required: true,
    },
    viewed: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    }
  },
  computed: {
    checkBtnText(): string {
      if (this.viewed) {
        return "確認済み";
      } else {
        return "確認する";
      }
    },
    testOperateKey(): string {
      return this.reAgreeTermItemForm.term.kind + "_open_btn"
    }
  },
  methods: {
    termChecked(): void {
      this.$emit('update:viewed', true)
    },
  },
});
