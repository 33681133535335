import * as vueMounter from "@scripts/vueMounter.ts";
import { commonComponents } from "@scripts/public/common.ts";

vueMounter.mount(commonComponents);

import { switchHeader } from "@scripts/public/utility/switchHeader.ts";
switchHeader();

import { accordion } from "@scripts/public/utility/accordion.ts";
accordion();

import { projectsSlider } from "@scripts/public/top_page/projectsSlider.ts";
projectsSlider();

import { flowSlider } from "@scripts/public/top_page/flowSlider.ts";
flowSlider();
