import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch'

import Rails from "@rails/ujs";
Rails.start();

// 共通components出力
import ErrorModal from "@components/partials/public/ErrorModal.vue";
import ReAgreeTermModal from "@components/partials/public/ReAgreeTermModal.vue";

export const commonComponents = {
  ErrorModal,
  ReAgreeTermModal
};

import { showAlert } from "@scripts/public/utility/showAlert.ts";
showAlert();
